import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Concept from 'components/Concept'
import NewsList from 'pages/NewsList'
import EventList from 'pages/EventList'
import TrackerLogoList from 'components/TrackerLogoList'
import MainImage from 'components/MainImage'
import CustomButton from 'components/CustomButton'

export default function Top() {
  const { palette, spacing } = useTheme()
  const { push } = useHistory()

  const More = ({ text, href }) => (
    <div
      style={{
        marginBottom: spacing(6),
        background: palette.background.paper,
        padding: spacing(3),
      }}
    >
      <CustomButton
        arrow
        onClick={() => {
          if (href.startsWith('/')) {
            push(href)
          } else {
            window.open(href)
          }
        }}
      >
        {text}
      </CustomButton>
    </div>
  )

  return (
    <div style={{ background: palette.background.default }}>
      <MainImage src="/images/main.jpg" alt="トップ" type="top" />
      <Concept />
      <More text="詳細を見る" href={process.env.REACT_APP_CONCEPT_HREF} />
      <NewsList limit={3} />
      <More text="お知らせ一覧" href="/news" />
      <EventList limit={6} />
      <More text="イベント一覧" href="/events" />
      <TrackerLogoList />
    </div>
  )
}
