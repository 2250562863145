import React, { useMemo } from 'react'
import { useTheme } from '@material-ui/core/styles'
import SectionHeader from 'components/SectionHeader'
import EventListItem from 'components/EventListItem'
import { useEvent } from 'hooks/useEvent'

export default function EventList({ limit }) {
  const { palette } = useTheme()
  const { eventResources } = useEvent()

  const filtered = useMemo(() => {
    return typeof limit === 'number' ? eventResources.slice(0, limit) : eventResources
  }, [limit, eventResources])

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="EVENT" />
      {filtered.map(resource => (
        <EventListItem key={resource.event.id} eventResource={resource} />
      ))}
    </div>
  )
}
