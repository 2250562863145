import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useAPI } from 'hooks/useAPI'
import { useAuth } from 'hooks/useAuth'
import useSessionStorage from 'hooks/useSessionStorage'
import useQuery from 'hooks/useQuery'

export default function TwitterLogin({ onError }) {
  const { getItem, setItem, keys } = useSessionStorage()
  const { replace, location } = useHistory()
  const { client } = useAPI()
  const { signInWithSns } = useAuth()
  const query = useQuery()
  const [secret] = useState(getItem(keys.TWITTER_SECRET, { remove: true }))

  useEffect(() => {
    if (query.oauth_token && query.oauth_verifier && secret) {
      client
        .post('/twitter/access_token', {
          request_token: query.oauth_token,
          request_token_secret: secret,
          verifier: query.oauth_verifier,
        })
        .then(({ data }) => {
          replace(location.pathname)
          signInWithSns({ ...data, provider: 'Twitter' }).catch(onError)
        })
    }
  }, [client, query, signInWithSns, onError, secret, replace, location])

  const signIn = () => {
    client
      .get('/twitter/authentication_url', {
        params: { redirect_url: window.location.href },
      })
      .then(res => {
        setItem(keys.TWITTER_SECRET, res.data.secret)
        window.open(res.data.url, '_self')
      })
  }

  return (
    <Button style={{ padding: 0, marginBottom: 12 }} onClick={signIn}>
      <img
        src={require('images/sns/twitter.png')}
        alt="Sign in with Twitter"
        style={{ width: 210, height: 40 }}
      />
    </Button>
  )
}
