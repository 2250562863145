import React, { useState, useEffect } from 'react'
import Markdown from 'react-markdown'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SectionHeader from 'components/SectionHeader'
import Typography from '@material-ui/core/Typography'
import { useNews } from 'hooks/useNews'

const useStyles = makeStyles(({ markdown }) => ({ markdown }))

export default function NewsList({ limit }) {
  const { spacing, palette } = useTheme()
  const [item, setItem] = useState(null)
  const { id } = useParams()
  const { getNews } = useNews()
  const classes = useStyles()

  useEffect(() => {
    getNews(id).then(setItem)
  }, [id, getNews])

  if (!item) {
    return null
  }

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="NEWS" />
      <div style={{ padding: spacing(3, 6) }}>
        <Typography style={{ marginBottom: spacing(1) }} variant="caption">
          {moment(item.publication_date).format('YYYY.MM.DD')}
        </Typography>
        <Typography style={{ marginBottom: spacing(3) }} variant="h3">
          {item.title}
        </Typography>
        <Markdown source={item.description} className={classes.markdown} />
      </div>
    </div>
  )
}
