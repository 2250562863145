import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Button from 'components/CustomButton'

export default function TrackerListItem({ disabled, tracker, onClick }) {
  const { spacing, palette } = useTheme()

  return (
    <ListItem style={{ padding: spacing(4), borderBottom: `1px solid ${palette.divider}` }}>
      <img
        style={{
          width: 40,
          height: 40,
          marginRight: spacing(4),
        }}
        src={tracker.icon}
        alt={tracker.name}
      />
      <div style={{ flex: 1 }}>
        <Typography variant="body2">{tracker.name}</Typography>
      </div>
      <Button id={tracker.id} onClick={onClick} style={{ width: 72 }} disabled={disabled}>
        選択
      </Button>
    </ListItem>
  )
}
