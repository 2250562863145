import React, { useState, useEffect, useCallback, useContext, createContext } from 'react'
import moment from 'moment'
import { useAPI } from 'hooks/useAPI'

const EventContext = createContext()

export const EventState = Object.freeze({
  BEFORE_ENTRY: 0,
  ENTRY_OPENING: 1,
  ENTRY_CLOSED: 2,
  OPENING: 3,
  UPLOAD_OPENING: 4,
  FINISHED: 5,
})

export const DistanceUnit = Object.freeze({
  METER: 1,
  MILE: 2,
})

export const ParticipantStatus = Object.freeze({
  UNREGISTERED: 0,
  REGISTERED: 1,
  CANCELED: 2,
})

export const Competition = Object.freeze({
  RUN: 1,
  WALK: 2,
})

const getEventState = (event, now) => {
  if (moment(event.end_time).add(6, 'days').endOf('day').diff(now) < 0) {
    return EventState.FINISHED
  } else if (moment(event.end_time).endOf('day').diff(now) < 0) {
    return EventState.UPLOAD_OPENING
  } else if (moment(event.start_time).diff(now) < 0) {
    return EventState.OPENING
  } else if (moment(event.entry_end_datetime).diff(now) < 0) {
    return EventState.ENTRY_CLOSED
  } else if (moment(event.entry_start_datetime).diff(now) < 0) {
    return EventState.ENTRY_OPENING
  } else {
    return EventState.BEFORE_ENTRY
  }
}

const getPaymentMethod = (event) => {
  // 開催日の5日前まではコンビニ決済も可能
  if (moment(event.start_time).add(-5, 'days').endOf('day').diff(moment()) < 0) {
    return ["credit_card"]
  } else {
    return ["credit_card","konbini"]
  }
}

export function EventProvider({ children }) {
  const [eventResources, setEventResources] = useState([])
  const [participantResources, setParticipantResources] = useState([])
  const { client } = useAPI()

  const init = useCallback(eventResource => {
    const { event, activity } = eventResource
    event.state = getEventState(event, moment())
    event.paymentMethod = getPaymentMethod(event)
    event.competitionText = event.competition === Competition.RUN ? 'ランニング' : 'ウォーキング'
    activity.uploaded = activity.id !== 0
    return { ...eventResource, event, activity }
  }, [])

  useEffect(() => {
    client
      .get('/vrwc/events', {
        params: {
          start_datetime: moment().format('YYYY-MM-DD 00:00:00'),
          end_datetime: moment().add(90, 'days').format('YYYY-MM-DD 23:59:59'),
        },
      })
      .then(res => setEventResources(res.data.events.map(x => init(x))))
  }, [client, init])

  const getParticipants = useCallback(() => {
    client.get('/vrwc/event_participant/me').then(res => {
      setParticipantResources(res.data.participants.map(x => init(x)))
    })
  }, [client, init])

  const getEvent = useCallback(
    id => {
      return client.get(`/vrwc/events/${id}`).then(res => init(res.data))
    },
    [client, init]
  )

  return (
    <EventContext.Provider
      value={{ eventResources, participantResources, getEvent, getParticipants, initEvent: init }}
      children={children}
    />
  )
}

export function useEvent() {
  return useContext(EventContext)
}
