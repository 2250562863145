import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useAuth } from 'hooks/useAuth'
import { useEvent, EventState } from 'hooks/useEvent'
import Guide from 'components/Guide'
import SectionHeader from 'components/SectionHeader'
import EventListItem from 'components/EventListItem'

export default function MyPage() {
  const { spacing, palette } = useTheme()
  const { participantResources, getParticipants } = useEvent()
  const { user } = useAuth()
  const { push } = useHistory()

  useEffect(() => {
    if (user) {
      getParticipants()
    }
  }, [user, getParticipants])

  if (user === null) {
    push('/login')
    return null
  }

  const past = participantResources.filter(({ event }) =>
    [EventState.FINISHED, EventState.UPLOAD_OPENING].includes(event.state)
  )
  const future = participantResources.filter(
    ({ event }) => ![EventState.FINISHED, EventState.UPLOAD_OPENING].includes(event.state)
  )

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(6, 3),
          background: palette.background.paper,
          marginBottom: spacing(6),
        }}
      >
        <Typography style={{ fontWeight: 'bold', marginBottom: spacing(2) }} variant="body1">
          {user.name}
        </Typography>
        <Typography style={{ marginBottom: spacing(6) }} variant="caption">
          会員ID：{user.id}
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography variant="body1" style={{ marginRight: spacing(2) }} color="textPrimary">
            参加回数
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {user.total_participant_count}
          </Typography>
          <div style={{ width: 1, margin: spacing(0, 2), background: palette.divider }} />
          <Typography variant="body1" style={{ marginRight: spacing(2) }} color="textPrimary">
            累計距離
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {parseInt(user.total_activity_distance / 10) / 100}km
          </Typography>
        </div>
      </div>
      {user.total_activity_distance === 0 && <Guide style={{ marginBottom: spacing(6) }} />}
      {future.length > 0 && (
        <div style={{ background: palette.background.paper, marginBottom: spacing(6) }}>
          <SectionHeader primary="参加予定のイベント" />
          {future.map(resource => (
            <EventListItem key={resource.event.id} eventResource={resource} enableCertificate />
          ))}
        </div>
      )}
      {past.length > 0 && (
        <div style={{ background: palette.background.paper }}>
          <SectionHeader primary="過去に参加したイベント" />
          {past.map(resource => (
            <EventListItem key={resource.event.id} eventResource={resource} enableCertificate />
          ))}
        </div>
      )}
      {user.total_activity_distance > 0 && <Guide style={{ marginTop: spacing(6) }} />}
    </div>
  )
}
