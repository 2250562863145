import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomTextField from 'components/CustomTextField'
import CustomButton from 'components/CustomButton'
import { useAuth } from 'hooks/useAuth'

const Schema = yup.object().shape({
  email: yup.string().required('必須項目です').email('メールアドレスの形式で入力してください'),
  password: yup.string().required('必須項目です').min(10, '10 文字以上で入力してください'),
})

export default function EmailLogin({ style, onError }) {
  const { spacing, palette } = useTheme()
  const { signInWithEmail } = useAuth()
  const { register, handleSubmit, errors } = useForm({
    defaultValues: Schema.cast(),
    validationSchema: Schema,
  })

  const onSubmit = data => {
    signInWithEmail(data).catch(onError)
  }

  return (
    <div style={{ padding: spacing(3, 0), ...style }}>
      <CustomTextField
        id="email"
        label="メールアドレス"
        register={register}
        placeholder="runtrip@example.com"
        error={errors.email}
      />
      <CustomTextField
        id="password"
        type="password"
        label="パスワード"
        register={register}
        placeholder="10 文字以上"
        error={errors.password}
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CustomButton
          style={{ margin: spacing(4, 0), width: 210 }}
          onClick={handleSubmit(onSubmit)}
        >
          ログイン
        </CustomButton>
        <a href="https://runtrip.jp/profile/forgetpswd" target="_blank" rel="noopener noreferrer">
          <Typography style={{ color: palette.link }} variant="body1">
            パスワードを忘れた方
          </Typography>
        </a>
      </div>
    </div>
  )
}
