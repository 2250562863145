import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export default function SectionHeader({ primary, secondary }) {
  const { palette, spacing } = useTheme()

  return (
    <div style={{ background: palette.background.paper, padding: spacing(6, 3, 3, 3) }}>
      {secondary && (
        <Typography
          style={{
            color: process.env.REACT_APP_COLOR_SECTION_HEADER_SUBTEXT,
            fontWeight: 'bold',
            marginBottom: spacing(1),
          }}
          variant="body1"
        >
          {secondary}
        </Typography>
      )}
      <Typography style={{ marginBottom: spacing(3) }} variant="h2">
        {primary}
      </Typography>
      <div style={{ height: 1, background: palette.divider }}>
        <div
          style={{
            height: 1,
            width: 120,
            background: process.env.REACT_APP_COLOR_SECTION_HEADER_BORDER,
          }}
        />
      </div>
    </div>
  )
}
