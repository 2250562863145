import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/ja'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useEvent, EventState, ParticipantStatus } from 'hooks/useEvent'
import MainImage from 'components/MainImage'
import SectionHeader from 'components/SectionHeader'
import EventInfoListItem from 'components/EventInfoListItem'
import Share from 'components/Share'
import EventAction from 'components/EventAction'
import { useAuth } from 'hooks/useAuth'

export default function Event() {
  const { push } = useHistory()
  const { palette, spacing } = useTheme()
  const [eventResource, setEventResource] = useState(null)
  const { user } = useAuth()
  const { id } = useParams()
  const { getEvent, initEvent } = useEvent()
  const { event, participant } = eventResource || {}

  useEffect(() => {
    getEvent(id)
      .then(setEventResource)
      .catch(error => push('/404'))
  }, [id, getEvent, push])

  const date = useMemo(() => {
    if (!event) return ''
    if (moment(event.start_time).isSame(event.end_time, 'day')) {
      return moment(event.start_time).format('YYYY/MM/DD')
    } else {
      return `${moment(event.start_time).format('YYYY/MM/DD')} ~ ${moment(event.end_time).format(
        'YYYY/MM/DD'
      )}`
    }
  }, [event])

  if (!eventResource) {
    return null
  }

  const imageOverlayText = (state, isFullParticipants) => {
    if (participant.status === ParticipantStatus.REGISTERED) {
      return null
    }

    switch (state) {
      case EventState.FINISHED:
      case EventState.UPLOAD_OPENING:
        return 'イベントは終了しました'
      case EventState.ENTRY_CLOSED:
      case EventState.OPENING:
        return 'イベントの受付は終了しました'
      default:
        return isFullParticipants ? '参加人数上限に達しました' : null
    }
  }

  const capacity = number => {
    // 定員 1,000,000 の場合 "上限なし" と表記
    return number === 1000000 ? '上限なし' : `${number}名`
  }

  const limit = time => {
    // 制限時間なし
    if (time === 0) {
      return '指定なし'
    }
    const hour = parseInt(time / 60)
    const min = time % 60
    return min > 0 ? `${hour}時間${min}分` : `${hour}時間`
  }

  const distance = number => {
    // 距離 0 の場合 "指定なし" と表記
    return number === 0 ? '指定なし' : `${event.distance / 1000}km`
  }

  return (
    <div>
      <MainImage
        src={event.cover_image_url}
        alt="イベント画像"
        type="event"
        overlayText={imageOverlayText(
          event.state,
          event.participant_count >= event.participant_count_max
        )}
      />
      <SectionHeader primary={event.title} secondary={date} />
      <Share />
      <div style={{ background: palette.background.paper, padding: spacing(3) }}>
        <EventInfoListItem label="種目" value={event.competitionText} />
        <EventInfoListItem label="距離" value={distance(event.distance)} />
        <EventInfoListItem
          label="開催期間"
          value={
            moment(event.start_time).format('YYYY/MM/DD(ddd) HH:mm') +
            '〜' +
            moment(event.end_time).format('YYYY/MM/DD(ddd) HH:mm')
          }
        />
        <EventInfoListItem label="制限時間" value={limit(event.limit_time)} />
        <EventInfoListItem label="参加費用" value={event.price > 0 ? `${event.price}円` : '無料'} />
        <EventInfoListItem
          label="申込受付期間"
          value={
            moment(event.entry_start_datetime).format('YYYY/MM/DD(ddd) HH:mm') +
            '〜' +
            moment(event.entry_end_datetime).format('YYYY/MM/DD(ddd) HH:mm')
          }
        />
        <EventInfoListItem label="定員" value={capacity(event.participant_count_max)} />
        <EventInfoListItem label="イベント内容" value={event.description} markdown />
        <EventInfoListItem label="注意事項" value={event.attention} markdown />
        <EventAction
          eventResource={eventResource}
          user={user}
          onUpdateEvent={value => setEventResource(initEvent(value))}
        />
      </div>
    </div>
  )
}
