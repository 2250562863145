import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from '@material-ui/styles'
import './index.css'
import App from './App'
import { theme } from 'theme'
import { APIProvider } from 'hooks/useAPI'
import { AuthProvider } from 'hooks/useAuth'
import { EventProvider } from 'hooks/useEvent'
import { NewsProvider } from 'hooks/useNews'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <CookiesProvider>
        <APIProvider>
          <AuthProvider>
            <EventProvider>
              <NewsProvider>
                <App />
              </NewsProvider>
            </EventProvider>
          </AuthProvider>
        </APIProvider>
      </CookiesProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
)
