import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ja'
import { useTheme } from '@material-ui/core/styles'
import { useParams, useHistory } from 'react-router-dom'
import { useEvent } from 'hooks/useEvent'
import Typography from '@material-ui/core/Typography'
import SectionHeader from 'components/SectionHeader'
import CustomButton from 'components/CustomButton'

export default function EntryComplete() {
  const { id } = useParams()
  const { push } = useHistory()
  const { spacing, palette } = useTheme()
  const [event, setEvent] = useState(null)
  const { getEvent } = useEvent()

  moment.locale('ja')

  useEffect(() => {
    getEvent(id).then(eventResource => setEvent(eventResource.event))
  }, [id, getEvent])

  if (!event) {
    return null
  }

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="参加申込完了" />
      <div style={{ padding: spacing(3) }}>
        <div
          style={{
            padding: spacing(3, 0),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" style={{ marginBottom: spacing(6) }}>
            {moment(event.start_time).format('YYYY年MM月DD日(ddd)')}
            <br />
            {event.title}
            <br />
            イベントに参加を申し込みました。
          </Typography>
          <Typography variant="body2" align="center">
            イベントに参加する前にお使いの計測機器の接続方法を確認してください。
          </Typography>
          <CustomButton
            style={{ maxWidth: 296, margin: spacing(3, 0) }}
            onClick={() => push(`/trackers`)}
            arrow
          >
            計測機器を接続する
          </CustomButton>
          <Link to="/users/me">
            <Typography style={{ color: palette.link }} variant="body1">
              マイページで参加状況を確認
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  )
}
