import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { useAPI } from 'hooks/useAPI'
import Footer from 'components/Footer'

export default function Container({ children }) {
  const { palette } = useTheme()
  const history = useHistory()
  const { initialized } = useAuth()
  const { requesting } = useAPI()

  // ページ遷移時に ScrollTop
  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0)
    })
  }, [history])

  return (
    <div
      style={{
        flex: 1,
        background: palette.background.default,
      }}
    >
      <div style={{ width: '100%', maxWidth: 800, margin: '0 auto' }}>
        {initialized && children}
      </div>
      <Footer />
      {(!initialized || requesting) && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
