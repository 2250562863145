import React, { useEffect, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import { useAuth } from 'hooks/useAuth'

export default function AppleLogin({ onError }) {
  const { signInWithSns } = useAuth()

  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    script.async = true

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const signIn = useCallback(async () => {
    try {
      window.AppleID.auth.init({
        clientId: 'jp.runtrip.web',
        scope: 'email',
        redirectURI: window.location.href,
        state: String(Math.ceil(Math.random() * 100000)),
        usePopup: true,
      })

      // 参考: https://blog.katsubemakito.net/articles/sign-in-with-apple-js
      const data = await window.AppleID.auth.signIn()
      const idToken = data.authorization.id_token.split('.')[1]
      const authInfo = JSON.parse(window.atob(idToken))
      signInWithSns({
        provider: 'Apple',
        provider_user_id: authInfo.sub,
        access_token: 'accesstoken',
        access_token_expires: 0,
        access_token_secret: ' ',
      }).catch(onError)
    } catch (error) {
      onError(error)
    }
  }, [signInWithSns, onError])

  return (
    <Button style={{ padding: 0, marginBottom: 12 }} onClick={signIn}>
      <img
        src={require('images/sns/apple.png')}
        alt="Sign in with Apple"
        style={{ width: 210, height: 40 }}
      />
    </Button>
  )
}
