import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Popup from 'components/Popup'

export default function ConnectionTestListItem({
  tracker,
  onClickConnect,
  onClickDisconnect,
  connected,
  disabled,
}) {
  const { spacing, palette } = useTheme()
  const [openConnectConfirm, setOpenConnectConfirm] = useState(false)
  const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState(false)

  return (
    <>
      <ListItem
        style={{
          alignItems: 'start',
          paddingBottom: 0,
          borderBottom: `1px solid ${palette.divider}`,
        }}
      >
        <img
          style={{ width: 40, height: 40, marginRight: spacing(4) }}
          src={tracker.icon}
          alt={tracker.name}
        />
        <div style={{ flex: 1 }}>
          <div style={{ minHeight: 40, display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <Typography variant="body2">{tracker.name}</Typography>
            </div>
            {connected ? (
              <Typography variant="body2" style={{ color: palette.success.main, fontSize: 12 }}>
                接続済み
              </Typography>
            ) : (
              <Typography variant="body2" style={{ color: '#9c9c9c', fontSize: 12 }}>
                未接続
              </Typography>
            )}
          </div>
          <Button
            variant="text"
            onClick={
              connected ? () => setOpenDisconnectConfirm(true) : () => setOpenConnectConfirm(true)
            }
            disabled={disabled}
            style={{
              color: palette.link,
              fontSize: 14,
              fontWeight: 'bold',
              padding: spacing(2, 3, 2),
              marginTop: -8,
            }}
          >
            {connected ? '接続解除' : '接続する'}
          </Button>
        </div>
      </ListItem>
      {openConnectConfirm && (
        <Popup
          open={openConnectConfirm}
          onClose={() => setOpenConnectConfirm(false)}
          title="計測機器との接続"
          description={
            <>
              <Typography variant="body2">
                GPS機能が搭載されているデバイスでの記録・または対応アプリでの記録が完走証発行の対象となります。
              </Typography>
              <br />
              <div style={{ textAlign: 'center' }}>
                <a
                  href={process.env.REACT_APP_CONNECTION_TEST_CONFIRM_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: palette.link }}
                >
                  詳細はこちら
                </a>
              </div>
            </>
          }
          buttonDirection="row"
          primaryText="接続する"
          onClickPrimary={() => {
            setOpenConnectConfirm(false)
            onClickConnect()
          }}
          secondaryText="キャンセル"
          onClickSecondary={() => setOpenConnectConfirm(false)}
        />
      )}
      {openDisconnectConfirm && (
        <Popup
          open={openDisconnectConfirm}
          onClose={() => setOpenDisconnectConfirm(false)}
          title="計測機器との接続"
          description={`${tracker.name}との接続を解除しますか？`}
          buttonDirection="row"
          primaryText="解除する"
          onClickPrimary={() => {
            setOpenDisconnectConfirm(false)
            onClickDisconnect()
          }}
          secondaryText="キャンセル"
          onClickSecondary={() => setOpenDisconnectConfirm(false)}
        />
      )}
    </>
  )
}
