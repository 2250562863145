import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import garmin from 'images/tracker/icons/garmin.png'
import polar from 'images/tracker/icons/polar.png'
import fitbit from 'images/tracker/icons/fitbit.png'
import suunto from 'images/tracker/icons/suunto.png'
import useSessionStorage from 'hooks/useSessionStorage'
import useQuery from 'hooks/useQuery'
import { useAPI } from 'hooks/useAPI'
import { useEvent, DistanceUnit } from 'hooks/useEvent'

export const trackers = {
  garmin: { id: 'garmin', name: 'GARMIN', provider: 'Garmin', icon: garmin },
  polar: { id: 'polar', name: 'Polar', provider: 'Polar', icon: polar },
  fitbit: { id: 'fitbit', name: 'Fitbit', provider: 'Fitbit', icon: fitbit },
  suunto: { id: 'suunto', name: 'Suunto', provider: 'Suunto', icon: suunto },
}

export const Error = {
  GET_AUTH_URL: 0,
  GET_ACCESS_TOKEN: 1,
  GET_ACTIVITIES: 2,
  UPLOAD_ACTIVITY_INVALID: 3,
  UPLOAD_ACTIVITY_OTHERS: 4,
  DISCONNECT: 5,
  POST_ACCESS_TOKEN: 6,
  GET_TRACKER_TOKENS: 7,
}

const invalidErrorMessage = {
  41010: 'データのアップロード有効期間が過ぎています。',
  41011: '開催時間外の計測データです。',
  41012: '制限時間を超えた計測データです。',
  41013: '計測データに異常値が見つかりました。',
  41014: '計測データが指定の距離に達していません。',
  41015: '計測データにGPS情報が含まれていません。',
  41016: '参加費のお支払いが完了していません。',
}

export function useTracker() {
  const { getItem, setItem, keys } = useSessionStorage()
  const [trackerId, setTrackerId] = useState(getItem(keys.TRACKER, { remove: true }))
  const [authInfo, setAuthInfo] = useState(null)
  const { client } = useAPI()
  const { getEvent } = useEvent()
  const [error, setError] = useState(null)
  const tracker = trackers[trackerId] || null
  const query = useQuery()
  const [eventResource, setEventResource] = useState(() => {
    const item = getItem(keys.EVENT, { remove: true })
    return item !== null ? JSON.parse(item) : null
  })
  const [connected, setConnected] = useState(false)
  const [activities, setActivities] = useState([])
  const [accessTokens, setAccessTokens] = useState(null)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const { replace, location } = useHistory()
  const isConnectionTest = !Boolean(query.eventId) && eventResource === null

  useEffect(() => {
    client
      .get(`/vrwc/tracking_service/access_token`)
      .then(res => setAccessTokens(res.data.access_tokens))
      .catch(() =>
        setError({
          type: Error.GET_TRACKER_TOKENS,
          message: 'ページの読み込みに失敗しました。通信環境の良い場所で再度お試しください。',
        })
      )
  }, [client])

  useEffect(() => {
    if (query.eventId) {
      getEvent(query.eventId).then(setEventResource)
    }
  }, [query, getEvent])

  const getAccessToken = useCallback(
    data => {
      return client
        .post(`/${trackerId}/access_token`, { ...data, client: process.env.REACT_APP_SUBDOMAIN })
        .then(res => {
          const data = res.data.access_token
          setAuthInfo(data)
          return data
        })
        .catch(() =>
          setError({
            type: Error.GET_ACCESS_TOKEN,
            message: '計測機器との接続に失敗しました、大変お手数ですが時間を空けてお試しください。',
          })
        )
    },
    [client, trackerId]
  )

  const getActivities = useCallback(
    authInfo => {
      if (eventResource) {
        return client
          .get(`/${trackerId}/activities`, {
            params: {
              client: process.env.REACT_APP_SUBDOMAIN,
              ...authInfo,
              start_datetime: eventResource.event.start_time,
              end_datetime: eventResource.event.end_time,
            },
          })
          .then(res => {
            const _activities = res.data.activities
            if (_activities.length === 0) {
              const message =
                trackerId === 'polar'
                  ? 'イベント期間内のアクティビティデータが存在しません、大変お手数ですが時間を空けてお試しください。また、事前接続したアカウントと計測に使用したアカウントが同じであることをご確認ください。Polar Beatをご利用の方は直ぐにデータが同期されない可能性がございます。下記リンク先に記載しております、手動でのデータ同期手順をお試しください。'
                  : 'イベント期間内のアクティビティデータが存在しません、大変お手数ですが時間を空けてお試しください。また、事前接続したアカウントと計測に使用したアカウントが同じであることをご確認ください。'

              setError({
                type: Error.GET_ACTIVITIES,
                message,
              })
            }
            return res.data.activities
          })
          .catch(() =>
            setError({
              type: Error.GET_ACTIVITIES,
              message: 'データの取得に失敗しました。',
            })
          )
      }
    },
    [client, trackerId, eventResource]
  )

  useEffect(() => {
    let data = null
    if (!trackerId) return
    if (trackerId === 'garmin' && query.oauth_token && query.oauth_verifier) {
      data = {
        request_token: query.oauth_token,
        request_token_secret: getItem(keys.GARMIN_SECRET, { remove: true }),
        verifier: query.oauth_verifier,
      }
    } else if (trackerId !== 'garmin' && query.code) {
      data = {
        authentication_code: query.code,
        redirect_url: `${window.location.origin}/trackers`,
      }
    }
    if (!data) return

    getAccessToken(data).then(authInfo => {
      if (!isConnectionTest) {
        getActivities(authInfo).then(setActivities)
      }
      if (tracker.id === 'garmin') {
        authInfo.access_token_expires = 0
      }
      authInfo.provider = tracker.provider
      replace(location.pathname)
      client
        .post('/vrwc/tracking_service/access_token', authInfo)
        .then(res => {
          setConnected(true)
          setAccessTokens(current => [...current, authInfo])
        })
        .catch(() =>
          setError({
            type: Error.POST_ACCESS_TOKEN,
            message:
              '計測機器との接続情報が確認出来ませんでした、大変お手数ですが時間を空けてお試しください。',
          })
        )
    })
  }, [
    query,
    getAccessToken,
    getActivities,
    keys,
    getItem,
    replace,
    trackerId,
    tracker,
    isConnectionTest,
    location,
    client,
  ])

  const connect = useCallback(
    trackerId => {
      return client
        .get(`/${trackerId}/authentication_url`, {
          params: {
            client: process.env.REACT_APP_SUBDOMAIN,
            redirect_url: `${window.location.origin}/trackers`,
          },
        })
        .then(res => {
          setItem(keys.TRACKER, trackerId)
          if (eventResource) {
            setItem(keys.EVENT, JSON.stringify(eventResource))
          }
          if (trackerId === 'garmin') {
            setItem(keys.GARMIN_SECRET, res.data.secret)
          }
          window.open(res.data.url, '_self')
        })
        .catch(() =>
          setError({
            type: Error.GET_AUTH_URL,
            message: '計測機器との接続に失敗しました、大変お手数ですが時間を空けてお試しください。',
          })
        )
    },
    [client, keys, setItem, eventResource]
  )

  const disconnect = useCallback(
    provider => {
      return client
        .delete(`/vrwc/tracking_service/access_token`, { data: { provider } })
        .then(res => setAccessTokens(current => current.filter(x => x.provider !== provider)))
        .catch(() =>
          setError({
            type: Error.DISCONNECT,
            message:
              '計測機器との接続解除に失敗しました、大変お手数ですが時間を空けてお試しください。',
          })
        )
    },
    [client]
  )

  const uploadActivity = useCallback(
    async id => {
      try {
        const params =
          tracker.id === 'garmin'
            ? {
                start_datetime: eventResource.event.start_time,
                end_datetime: eventResource.event.end_time,
              }
            : {}

        const activity = await client
          .get(`/${tracker.id}/activities/${id}`, {
            params: { client: process.env.REACT_APP_SUBDOMAIN, ...params, ...authInfo },
          })
          .then(res => res.data)

        if (eventResource.activity.uploaded) {
          await client.put(`/vrwc/event_activity/${eventResource.activity.id}`, {
            ...activity,
            provider_activity_id: String(activity.id),
            distance_unit: DistanceUnit.METER,
            provider: tracker.provider,
          })
        } else {
          await client.post('/vrwc/event_activity', {
            ...activity,
            provider_activity_id: String(activity.id),
            distance_unit: DistanceUnit.METER,
            event_id: eventResource.event.id,
            provider: tracker.provider,
          })
        }

        setUploadCompleted(true)

        return
      } catch (e) {
        if (e.status === 500) {
          setError({
            type: Error.UPLOAD_ACTIVITY_OTHERS,
            message: 'データの転送に失敗しました。',
          })
        } else {
          setError({
            type: Error.UPLOAD_ACTIVITY_INVALID,
            message:
              invalidErrorMessage[e.data.errors[0].code] ||
              'アップロードされたデータに不備がありました、データを選択し直すか主催者へお問い合わせください。',
          })
        }
      }
    },
    [client, authInfo, tracker, eventResource]
  )

  return {
    tracker,
    connect,
    connected,
    disconnect,
    activities,
    uploadActivity,
    eventResource,
    error,
    clearTracker: () => setTrackerId(null),
    clearError: () => setError(null),
    isConnectionTest,
    setConnected,
    uploadCompleted,
    setUploadCompleted,
    accessTokens,
  }
}
