import { createMuiTheme } from '@material-ui/core/styles'

const textPrimary = '#212121'
const textSecondary = '#4b4b4b'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY,
    },
    secondary: {
      main: '#0d66d0',
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
    action: {
      active: textSecondary,
    },
    divider: 'rgba(0,0,0,0.15)',
    link: '#0074ff',
    success: {
      main: '#3ac2a8',
    },
    error: {
      main: '#f26161',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: "'Hiragino Kaku Gothic Pro', 'HiraKakuPro-W6', sans-serif",
    h1: { fontSize: 22, fontWeight: 'bold', colog: textPrimary },
    h2: { fontSize: 20, fontWeight: 'bold', color: textPrimary },
    h3: { fontSize: 18, fontWeight: 'bold', color: textPrimary },
    body1: { fontSize: 16, color: textPrimary },
    body2: { fontSize: 16, color: textSecondary },
    caption: { fontSize: 12, color: textSecondary },
    button: { fontSize: 16, color: '#e60000' },
  },
  markdown: {
    color: textSecondary,
    '& img': {
      maxWidth: '100%',
    },
    '& h2': {
      color: textPrimary,
      lineHeight: 1.4,
      marginTop: 24,
      marginBottom: 0,
      fontSize: 18,
      fontWeight: 'normal',
      '&:first-child': {
        marginTop: 8,
      },
    },
    '& ul, ol': {
      paddingLeft: 20,
    },
    '& li > ol, li > ul': {
      paddingLeft: 12,
    },
  },
})
