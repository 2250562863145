import React, { useState, useCallback } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomButton from 'components/CustomButton'
import Popup from 'components/Popup'
import { EventState, ParticipantStatus } from 'hooks/useEvent'
import { useAPI } from 'hooks/useAPI'

export default function EventAction({ eventResource, user, onUpdateEvent }) {
  const [open, setOpen] = useState(false)
  const { palette, spacing } = useTheme()
  const { id } = useParams()
  const { push } = useHistory()
  const { client } = useAPI()
  const { event, activity, participant } = eventResource || {}
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'))
  const [cancelSucceeded, setCancelSucceeded] = useState(false)
  const [cancelFailed, setCancelFailed] = useState(false)

  const cancel = useCallback(() => {
    setOpen(false)
    client
      .delete(`/vrwc/event_participant/${participant.id}`)
      .then(res => {
        onUpdateEvent(res.data)
        setCancelSucceeded(true)
      })
      .catch(() => setCancelFailed(true))
  }, [client, participant, onUpdateEvent])

  const Button = ({ children, caption, disabled, onClick, variant = 'contained' }) => {
    if (disabled || !xs) {
      return (
        <div style={{ padding: spacing(3, 0) }}>
          {caption && (
            <Typography style={{ margin: spacing(6, 0) }} variant="body2">
              {caption}
            </Typography>
          )}
          <CustomButton variant={variant} disabled={disabled} onClick={onClick}>
            {children}
          </CustomButton>
        </div>
      )
    }

    return (
      <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          padding: spacing(3),
          zIndex: 100,
        }}
      >
        <CustomButton variant={variant} onClick={onClick}>
          {children}
        </CustomButton>
      </div>
    )
  }

  const Action = useCallback(() => {
    switch (event.state) {
      case EventState.BEFORE_ENTRY:
        return (
          <Button disabled caption="このイベントは申し込み受付前です">
            このイベントに参加する
          </Button>
        )

      case EventState.ENTRY_OPENING:
        if (!user) {
          const entryPath = encodeURIComponent(`/events/${id}/entry`)
          return (
            <Button variant="contained" onClick={() => push(`/login?continue=${entryPath}`)}>
              このイベントに参加する
            </Button>
          )
        }

        if (participant.status === ParticipantStatus.REGISTERED) {
          return (
            <Button variant="outlined" onClick={() => setOpen(true)}>
              参加をキャンセルする
            </Button>
          )
        }

        // 参加人数上限に達した場合
        if (event.participant_count >= event.participant_count_max) {
          return null
        }

        return (
          <Button variant="contained" onClick={() => push(`/events/${id}/entry`)}>
            このイベントに参加する
          </Button>
        )

      case EventState.ENTRY_CLOSED:
        // エントリー済み
        if (participant.status === ParticipantStatus.REGISTERED) {
          return (
            <Button variant="outlined" onClick={() => setOpen(true)}>
              参加をキャンセルする
            </Button>
          )
        }
        return null

      case EventState.OPENING:
      case EventState.UPLOAD_OPENING:
        // 未エントリー
        if (participant.status !== ParticipantStatus.REGISTERED) {
          return null
        }

        // 結果アップロード済み
        if (activity.uploaded) {
          return (
            <Button
              disabled
              caption={
                <>
                  このイベントの結果は既にアップロードされています。違う結果をアップロードするには
                  <Link style={{ color: palette.secondary.main }} to={`/trackers?eventId=${id}`}>
                    こちら
                  </Link>
                  から。
                </>
              }
            >
              結果をアップロードする
            </Button>
          )
        }

        // 結果アップロード待ち
        return (
          <Button variant="outlined" onClick={() => push(`/trackers?eventId=${id}`)}>
            結果をアップロードする
          </Button>
        )

      default:
        return null
    }
  }, [event, activity, id, participant, user, push, palette, xs]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Action />
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        title="申し込みキャンセル"
        description="本当にこのイベントの申し込みをキャンセルしてもよろしいですか？"
        primaryText="はい"
        onClickPrimary={cancel}
        secondaryText="いいえ"
        onClickSecondary={() => setOpen(false)}
        buttonDirection="row"
      />
      {cancelSucceeded && (
        <Popup
          id="event_cancel"
          open
          onClose={() => setCancelSucceeded(false)}
          title="申し込みキャンセル"
          description="イベントの申し込みをキャンセルしました。"
          primaryText="OK"
          onClickPrimary={() => setCancelSucceeded(false)}
        />
      )}
      {cancelFailed && (
        <Popup
          open
          onClose={() => setCancelFailed(false)}
          title="エラーが発生しました"
          description="イベントの申し込みキャンセルに失敗しました。"
          primaryText="OK"
          onClickPrimary={() => setCancelFailed(false)}
        />
      )}
    </>
  )
}
