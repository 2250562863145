import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton'

export default function Error() {
  const { push } = useHistory()
  const { spacing } = useTheme()

  return (
    <div style={{ padding: spacing(6), textAlign: 'center' }}>
      <Typography style={{ fontSize: 100, fontWeight: 'bold', color: '#bbb' }}>404</Typography>
      <Typography style={{ fontSize: 36, fontWeight: 'bold', color: '#bbb', marginTop: -30 }}>
        Not Found
      </Typography>
      <Typography style={{ margin: spacing(6, 0) }} variant="h3">
        ご指定のページを表示できません
      </Typography>
      <CustomButton style={{ margin: '0 auto', maxWidth: 300 }} arrow onClick={() => push('/')}>
        トップページ
      </CustomButton>
    </div>
  )
}
