import React from 'react'

export default function MultilineText({ children }) {
  return children.split('\n').map((text, i) => (
    <span key={i}>
      {text}
      <br />
    </span>
  ))
}
