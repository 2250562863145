import React, { useState, useEffect, useCallback, useContext, createContext } from 'react'
import moment from 'moment'
import { useAPI } from 'hooks/useAPI'

const NewsContext = createContext()

export function NewsProvider({ children }) {
  const [news, setNews] = useState([])
  const { client } = useAPI()

  const init = item => {
    // 1 週間以内に投稿されたデータに isNew: true をセット
    const comparison = moment().add(-7, 'days')
    const isNew = moment(item.publication_date).diff(comparison) > 0
    return { ...item, isNew }
  }

  useEffect(() => {
    client.get('/vrwc/news').then(res => setNews(res.data.news.map(x => init(x))))
  }, [client])

  const getNews = useCallback(
    async id => {
      // セッション中にすでに取得済みのキャッシュから返す
      const item = news.find(x => x.id === Number(id))
      if (item) {
        return item
      }

      // API リクエスト
      const res = await client.get(`/vrwc/news/${id}`)
      return init(res.data)
    },
    [client, news]
  )

  return <NewsContext.Provider value={{ news, getNews }} children={children} />
}

export function useNews() {
  return useContext(NewsContext)
}
