import React from 'react'
import { Controller } from 'react-hook-form'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  label: {
    color: '#9c9c9c',
    '&.MuiFormLabel-filled': {
      display: 'none',
    },
    '&.MuiFormLabel-root.Mui-focused': {
      color: '#9c9c9c',
    },
    '&.MuiFormLabel-root.Mui-error': {
      color: '#9c9c9c',
    },
  },
}))

export default function CustomSelect({ id, label, options, control, rules, error }) {
  const classes = useStyles()
  const { spacing } = useTheme()

  return (
    <div style={{ padding: spacing(2, 0) }}>
      <Typography variant="caption" color={error === undefined ? 'textSecondary' : 'error'}>
        {label}
      </Typography>
      <FormControl style={{ marginTop: spacing(-4), width: '100%' }} error={error !== undefined}>
        <InputLabel id={`${id}-label`} shrink={false} className={classes.label}>
          選択してください
        </InputLabel>
        <Controller
          as={
            <Select
              color="secondary"
              labelId={`${id}-label`}
              id={id}
              placeholder="選択してください"
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          }
          onChange={([e]) => {
            return e.target.value
          }}
          defaultValue=""
          control={control}
          name={id}
          rules={rules}
        />
        {error && <FormHelperText>必須項目です</FormHelperText>}
      </FormControl>
    </div>
  )
}
