import React, { useEffect, useState, useCallback, useContext, createContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useAPI } from 'hooks/useAPI'
import { useHistory } from 'react-router-dom'
import useQuery from 'hooks/useQuery'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const { push } = useHistory()
  const [user, setUser] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const { client, setAccessToken, accessToken, removeCookies } = useAPI()
  const { search } = useLocation()
  const query = useQuery()

  useEffect(() => {
    if (accessToken) {
      // ログイン状態
      client
        .get('/vrwc/user/me')
        .then(res => {
          setUser(res.data)
          setInitialized(true)
        })
        .catch(error => {
          removeCookies()
          setInitialized(true)
        })
    } else {
      // 非ログイン状態
      setInitialized(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const signInWithRuntrip = useCallback(
    async ({ user, access_token, expires_in }) => {
      const data = {
        provider: 'Runtrip',
        provider_user_id: String(user.id),
        access_token,
        access_token_expires: expires_in,
      }
      const res = await client.post('/vrwc/access_token', data).catch(error => {
        const { errors } = error.data
        const USER_NOT_EXISTS_ERROR_CODE = '40103'
        // Runtrip のユーザー登録済かつ VRWC 未登録ユーザー
        if (errors.length === 1 && errors[0].code === USER_NOT_EXISTS_ERROR_CODE) {
          push({ pathname: '/signup', search, state: { ...data, user_name: user.name } })
        }
        return error
      })

      if (res.status === 200) {
        setUser(res.data.user)
        setAccessToken(res.data)
        const path = Boolean(query.continue) ? decodeURIComponent(query.continue) : '/'
        push(path)
      }

      return res
    },
    [client, setAccessToken, push, query, search]
  )

  const signInWithSns = useCallback(
    async data => {
      const response = await client.post('/auth/runtrip/sns_user/token', data)
      const res = await signInWithRuntrip(response.data)
      return res
    },
    [client, signInWithRuntrip]
  )

  const signInWithEmail = useCallback(
    data => {
      return client
        .post('/auth/runtrip/email_user/token', data)
        .then(res => signInWithRuntrip(res.data))
    },
    [client, signInWithRuntrip]
  )

  const signUp = useCallback(
    data => {
      return client.post('/vrwc/user_register', data).then(res => {
        setUser(res.data.user)
        setAccessToken(res.data)
      })
    },
    [client, setAccessToken]
  )

  const signOut = useCallback(() => {
    removeCookies()
    setUser(null)
    push('/')
  }, [removeCookies, push])

  return (
    <AuthContext.Provider
      value={{ initialized, user, signInWithEmail, signInWithSns, signUp, signOut }}
      children={children}
    />
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
