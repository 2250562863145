import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ja'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useEvent } from 'hooks/useEvent'
import { useAPI } from 'hooks/useAPI'
import { sexes, prefectures } from 'config'
import Typography from '@material-ui/core/Typography'
import CustomSelect from 'components/CustomSelect'
import CustomTextField from 'components/CustomTextField'
import CouponCodeInput from 'components/CouponCodeInput'
import BirthdayPicker from 'components/BirthdayPicker'
import CustomButton from 'components/CustomButton'
import SectionHeader from 'components/SectionHeader'
import Popup from 'components/Popup'

const validateFormValue = data => {
  if (data.postal_code) {
    data.postal_code = validatePostalCode(data.postal_code).value
  }
  if (data.phone_number) {
    data.phone_number = validatePhoneNumber(data.phone_number).value
  }
  return data
}

const validatePhoneNumber = value => {
  const trimmed = value.replace(/-/g, '')
  const formatted = trimmed.replace(/[０-９]/g, str =>
    String.fromCharCode(str.charCodeAt(0) - 65248)
  )
  const valid = /[0-9]{10,11}/.test(formatted) && [10, 11].includes(formatted.length)

  return { valid, value: formatted }
}

const validatePostalCode = value => {
  const trimmed = value.replace(/-/g, '')
  const formatted = trimmed.replace(/[０-９]/g, str =>
    String.fromCharCode(str.charCodeAt(0) - 65248)
  )
  const valid = /[0-9]{7}/.test(formatted) && formatted.length === 7

  return { valid, value: formatted }
}

export default function Entry() {
  const { id } = useParams()
  const { getEvent } = useEvent()
  const { push } = useHistory()
  const [event, setEvent] = useState(null)
  const { palette, spacing } = useTheme()
  const { client, requesting } = useAPI()
  const [error, setError] = useState(null)
  const { register, control, handleSubmit, errors, setValue } = useForm()
  const [coupon, setCoupon] = useState(null)
  const price = useMemo(() => {
    if (event === null || event.use_payment === false) return null
    if (coupon !== null) return coupon.discount_price
    return event.price
  }, [coupon, event])

  useEffect(() => {
    register('birthday', { required: '必須項目です' })
  }, [register])

  const onSubmit = useCallback(
    data => {
      // Formの値のValidate
      data = validateFormValue(data)
      data.event_id = id
      // クーポンで無料になったとき
      if (event.use_payment) {
        data.payment_token = null
        data.coupon_code = coupon.code
      }

      client
        .post('/vrwc/event_participant', data)
        .then(() => push(`/events/${id}/entry/complete`))
        .catch(setError)
    },
    [client, id, push, event, coupon]
  )

  const onPayment = useCallback(
    data => {
      // Formの値のValidate
      data = validateFormValue(data)
      data.event_id = id
      if (coupon) {
        data.coupon_code = coupon.code
      }

      let handler = window.Komoju.multipay.configure({
        key: process.env.REACT_APP_KOMOJU_PUBLIC_KEY,
        token: function (token) {
          client
            .post('/vrwc/event_participant', { ...data, event_id: id, payment_token: token.id })
            .then(() => push(`/events/${id}/entry/complete`))
            .catch(setError)
        },
      })

      handler.open({
        amount: price,
        locale: 'ja',
        currency: 'JPY',
        title: event.title,
        description: 'イベント参加費用',
        methods: event.paymentMethod,
      })
    },
    [client, id, push, event, price, coupon]
  )

  useEffect(() => {
    getEvent(id).then(eventResource => setEvent(eventResource.event))
  }, [id, getEvent])

  if (!event) {
    return null
  }

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader
        primary={event.title}
        secondary={moment(event.start_time).format('YYYY/MM/DD')}
      />
      <div style={{ padding: spacing(3) }}>
        <CustomTextField
          id="user_name"
          label="お名前(フルネーム)"
          register={register({ required: '必須項目です' })}
          placeholder="山田太郎"
          error={errors.user_name}
        />
        <BirthdayPicker onChange={v => setValue('birthday', v)} error={errors.birthday} />
        <CustomTextField
          id="email"
          label="メールアドレス"
          register={register({
            required: '必須項目です',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'メールアドレスの形式で入力してください',
            },
          })}
          placeholder="example@sample.com"
          error={errors.email}
        />
        <CustomSelect
          id="sex"
          label="性別"
          control={control}
          rules={{ required: '必須項目です' }}
          options={sexes}
          error={errors.sex}
        />
        <CustomSelect
          id="prefectures_id"
          label="居住地"
          control={control}
          rules={{ required: '必須項目です' }}
          options={prefectures}
          error={errors.prefectures_id}
        />
        {event.use_phone_number && (
          <CustomTextField
            id="phone_number"
            label="電話番号"
            register={register({
              validate: v => validatePhoneNumber(v).valid,
            })}
            placeholder="0368693154"
            error={errors.phone_number && { message: '入力形式が不正です' }}
          />
        )}
        {event.use_postal_code && (
          <CustomTextField
            id="postal_code"
            label="郵便番号"
            register={register({
              validate: v => validatePostalCode(v).valid,
            })}
            placeholder="1500031"
            error={errors.postal_code && { message: '入力形式が不正です' }}
          />
        )}
        {event.use_address && (
          <CustomTextField
            id="address"
            label="住所"
            register={register({ required: '必須項目です' })}
            placeholder="東京都渋谷区桜丘町23番17号シティコート桜丘408"
            error={errors.address}
          />
        )}
        {event.use_optional_form_1 && (
          <CustomTextField
            id="optional_form_value_1"
            label={event.optional_form_label_1}
            register={register({ required: '必須項目です', maxLength: 100 })}
            error={errors.optional_form_value_1 && { message: '100 文字以内で入力してください' }}
          />
        )}
        {event.use_optional_form_2 && (
          <CustomTextField
            id="optional_form_value_2"
            label={event.optional_form_label_2}
            register={register({ required: '必須項目です', maxLength: 100 })}
            error={errors.optional_form_value_2 && { message: '100 文字以内で入力してください' }}
          />
        )}
        {event.use_optional_form_3 && (
          <CustomTextField
            id="optional_form_value_3"
            label={event.optional_form_label_3}
            register={register({ required: '必須項目です', maxLength: 100 })}
            error={errors.optional_form_value_3 && { message: '100 文字以内で入力してください' }}
          />
        )}
        {event.use_payment && <CouponCodeInput onSuccess={setCoupon} />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(3, 3, 6),
        }}
      >
        <Typography style={{ marginBottom: spacing(3) }} variant="caption">
          ※お名前は入力された通りに完走証に記載されます
        </Typography>
        {event.use_payment && price > 0 ? (
          <CustomButton disabled={requesting} onClick={handleSubmit(onPayment)}>
            イベント参加費({price}円)を支払って参加
          </CustomButton>
        ) : (
          <CustomButton disabled={requesting} onClick={handleSubmit(onSubmit)}>
            参加を申し込む
          </CustomButton>
        )}
      </div>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title="エラーが発生しました"
        description="イベントへの申し込みに失敗しました。"
        primaryText="OK"
        onClickPrimary={() => setError(null)}
      />
    </div>
  )
}
