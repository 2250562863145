import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SectionHeader from 'components/SectionHeader'
import FacebookLogin from 'components/FacebookLogin'
import TwitterLogin from 'components/TwitterLogin'
import GoogleLogin from 'components/GoogleLogin'
import AppleLogin from 'components/AppleLogin'
import EmailLogin from 'components/EmailLogin'
import Popup from 'components/Popup'
import { ReactComponent as NewWindowIcon } from 'images/icons/new-window.svg'

export default function Login() {
  const { spacing, palette } = useTheme()
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))
  const [error, setError] = useState(null)

  const Divider = ({ horizontal }) => {
    const wrapperStyle = horizontal ? {} : { flexGrow: 2, minWidth: 72 }
    const borderStyle = horizontal
      ? { width: '100%', height: 1, margin: spacing(3, 0) }
      : { width: 1, height: 240, margin: 'auto' }
    return (
      <div style={{ position: 'relative', ...wrapperStyle }}>
        <div style={{ background: palette.divider, ...borderStyle }} />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: spacing(0, 2),
            background: palette.background.paper,
          }}
        >
          OR
        </div>
      </div>
    )
  }

  const SocialLogin = ({ style }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...style,
      }}
    >
      <FacebookLogin onError={setError} />
      <TwitterLogin onError={setError} />
      <GoogleLogin onError={setError} />
      <AppleLogin onError={setError} />
    </div>
  )

  return (
    <div style={{ background: palette.background.default, paddingTop: xs ? 0 : spacing(6) }}>
      <SectionHeader primary="Runtripアカウントでログイン" />
      {xs ? (
        <div
          style={{
            background: palette.background.paper,
            padding: spacing(3),
            marginBottom: spacing(6),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SocialLogin />
          <Divider horizontal />
          <EmailLogin onError={setError} />
        </div>
      ) : (
        <div
          style={{
            background: palette.background.paper,
            padding: spacing(3),
            marginBottom: spacing(6),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }} />
          <SocialLogin style={{ width: 210 }} />
          <Divider />
          <EmailLogin style={{ width: 296 }} onError={setError} />
          <div style={{ flexGrow: 1 }} />
        </div>
      )}
      <SectionHeader primary="Runtripアカウントを新規登録" />
      <div style={{ background: palette.background.paper, padding: spacing(6, 3) }}>
        <Typography variant="body2">
          Runtripアカウントをお持ちではない方は下記より新規登録してください。
        </Typography>
        <div
          style={{
            padding: spacing(6, 0, 3),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href={`https://runtrip.jp/register?backUrl=${encodeURIComponent(window.location.href)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography style={{ color: palette.link, marginRight: spacing(2) }} variant="body1">
              Runtripアカウントを作成
            </Typography>
          </a>
          <NewWindowIcon />
        </div>
      </div>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title=""
        description="Runtripアカウントが見つかりませんでした。アカウントをお持ちでない方は以下より作成ください。"
        primaryText="OK"
        onClickPrimary={() => setError(null)}
        secondaryText="Runtripアカウント新規作成"
        onClickSecondary={() =>
          window.open(
            `https://runtrip.jp/register?backUrl=${encodeURIComponent(window.location.href)}`
          )
        }
      />
    </div>
  )
}
