import React, { useState, useMemo } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ActivityListPopup from 'components/ActivityListPopup'
import ActivityResult from 'components/ActivityResult'
import TrackerListItem from 'components/TrackerListItem'
import ConnectionTestListItem from 'components/ConnectionTestListItem'
import Popup from 'components/Popup'
import { useAPI } from 'hooks/useAPI'
import { useAuth } from 'hooks/useAuth'
import { useTracker, trackers, Error } from 'hooks/useTracker'

export default function TrackerList() {
  const {
    tracker,
    clearTracker,
    connect,
    connected,
    disconnect,
    activities,
    uploadActivity,
    eventResource,
    error,
    clearError,
    isConnectionTest,
    setConnected,
    uploadCompleted,
    setUploadCompleted,
    accessTokens,
  } = useTracker()

  const { palette, spacing } = useTheme()
  const { push } = useHistory()
  const { requesting } = useAPI()
  const [selectedActivity, setSelectedActivity] = useState(null)
  const { user } = useAuth()

  const popup = useMemo(() => {
    if (error) {
      switch (error.type) {
        case Error.GET_AUTH_URL:
        case Error.GET_ACCESS_TOKEN:
        case Error.GET_ACTIVITIES:
        case Error.GET_TRACKER_TOKENS:
        case Error.DISCONNECT:
        case Error.POST_ACCESS_TOKEN:
          return (
            <Popup
              id="activity_upload_error"
              open
              onClose={clearError}
              title="エラーが発生しました"
              description={error.message}
              primaryText="OK"
              onClickPrimary={clearError}
              secondaryText="詳細はこちら"
              onClickSecondary={() => window.open(process.env.REACT_APP_FAQ_UPLOAD_ERROR_URL)}
            />
          )
        case Error.UPLOAD_ACTIVITY_INVALID:
          return (
            <Popup
              id="activity_upload_error"
              open
              onClose={() => {
                clearTracker()
                setSelectedActivity(null)
                clearError()
              }}
              title="エラーが発生しました"
              description={error.message}
              primaryText="OK"
              onClickPrimary={() => {
                clearTracker()
                setSelectedActivity(null)
                clearError()
              }}
              secondaryText="詳細はこちら"
              onClickSecondary={() => window.open(process.env.REACT_APP_FAQ_UPLOAD_ERROR_URL)}
            />
          )
        case Error.UPLOAD_ACTIVITY_OTHERS:
          return (
            <Popup
              id="activity_upload_error"
              open
              onClose={() => {
                clearTracker()
                setSelectedActivity(null)
                clearError()
              }}
              title="エラーが発生しました"
              description={error.message}
              primaryText="OK"
              onClickPrimary={() => {
                clearTracker()
                setSelectedActivity(null)
                clearError()
              }}
              secondaryText="アップロードに関するよくある質問"
              onClickSecondary={() => window.open(process.env.REACT_APP_FAQ_UPLOAD_ACTIVITY_URL)}
            />
          )
        default:
          break
      }
    }

    if (uploadCompleted) {
      return (
        <Popup
          id="activity_upload_success"
          open
          onClose={() => setUploadCompleted(false)}
          title="アップロード成功"
          description="計測データのアップロードが完了しました。"
          primaryText="完走証を表示"
          onClickPrimary={() => {
            setUploadCompleted(false)
            push(`/events/${eventResource.event.id}/activities/me`)
          }}
        />
      )
    }

    if (isConnectionTest && connected) {
      return (
        <Popup
          open
          onClose={() => setConnected(false)}
          title="計測機器との接続"
          description="計測機器との接続に成功しました。"
          primaryText="OK"
          onClickPrimary={() => setConnected(false)}
        />
      )
    }

    if (tracker && selectedActivity) {
      return (
        <Popup
          open={tracker !== null && selectedActivity !== null}
          onClose={() => {
            clearTracker()
            setSelectedActivity(null)
          }}
          title={
            <div>
              {tracker.name}
              <br />
              アクティビティデータ
            </div>
          }
          description={<ActivityResult activity={selectedActivity} />}
          primaryText="アップロード"
          onClickPrimary={() => uploadActivity(selectedActivity.id)}
          secondaryText="選択し直す"
          onClickSecondary={() => setSelectedActivity(null)}
          buttonDirection="row"
        />
      )
    }

    if (tracker && activities.length > 0) {
      return (
        <ActivityListPopup
          tracker={tracker}
          activities={activities}
          onSelect={setSelectedActivity}
          onClose={clearTracker}
        />
      )
    }

    return null
  }, [
    tracker,
    clearTracker,
    selectedActivity,
    connected,
    activities,
    uploadActivity,
    error,
    clearError,
    setConnected,
    isConnectionTest,
    push,
    eventResource,
    uploadCompleted,
    setUploadCompleted,
  ])

  if (user === null) {
    return <Redirect to="/login" />
  }

  if (isConnectionTest && accessTokens === null) {
    return Boolean(error) ? <>{popup}</> : null
  }

  return (
    <div
      id="tracker-list"
      data-mode={isConnectionTest ? 'connection-test' : 'activity-upload'}
      data-tracker={Boolean(tracker) ? tracker.id : ''}
      style={{ background: palette.background.paper, padding: spacing(3, 0, 0) }}
    >
      <Typography style={{ margin: spacing(3, 3) }} variant="body2" align="center">
        計測に利用{isConnectionTest ? 'する' : 'した'}サービスを選択してください。
      </Typography>
      <div>
        {Object.values(trackers).map(tracker =>
          isConnectionTest ? (
            <ConnectionTestListItem
              key={tracker.id}
              disabled={requesting}
              tracker={tracker}
              isConnectionTest={isConnectionTest}
              connected={accessTokens.some(x => x.provider === tracker.provider)}
              onClickConnect={() => connect(tracker.id)}
              onClickDisconnect={() => disconnect(tracker.provider)}
            />
          ) : (
            <TrackerListItem
              key={tracker.id}
              disabled={requesting}
              tracker={tracker}
              onClick={() => connect(tracker.id)}
            />
          )
        )}
      </div>
      {isConnectionTest && (
        <div style={{ padding: spacing(0, 3) }}>
          <Typography style={{ marginTop: spacing(3) }} variant="body2">
            ※GPSデータが含まれない、計測データに関してはアップロード出来ませんので事前に計測状況をご確認ください。
            <br />
          </Typography>
          <Typography
            style={{ marginTop: spacing(3), color: palette.link, cursor: 'pointer' }}
            variant="body2"
            onClick={() => window.open('https://runtrip.zendesk.com/hc/ja/articles/360057419193')}
          >
            AppleWatchをご利用の方へ
          </Typography>
          <Typography
            style={{ marginTop: spacing(3), color: palette.link, cursor: 'pointer' }}
            variant="body2"
            onClick={() => window.open(process.env.REACT_APP_CONNECT_TRACKERS_URL)}
          >
            各サービスの接続方法について
          </Typography>
        </div>
      )}
      {popup}
    </div>
  )
}
